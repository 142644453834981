import BaseBean from "@/utils/BaseBean";

export interface IHdxxCardDataObj {
    utilInst:HdxxCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class HdxxCardUtil extends BaseBean{
    public dataObj:IHdxxCardDataObj;

    constructor(proxy:any,dataObj:IHdxxCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        //货代公司
        this.dataObj.otherParams.hdxxData=await this.utils.OrderProviderApi.getAllHdxx({});
        this.dataObj.otherParams.qpData=await this.utils.OrderProviderApi.getAllQp({params:{queryType:2}});
        // this.dataObj.otherParams.hdgsData=await this.utils.OrderProviderApi.getAllHdgs({});
    }
    //提交
    public async submit():Promise<void>{
        this.utils.Tools.configBox({
            message:'确定提交吗?',
            sureFn:async ()=> {
                this.dataObj.form.saveType='submit';
                await this.proxy.engine.saveHandler();
            }
        });
    }
}